import { Box, Drawer, Stack, Tooltip, styled } from '@mui/material';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import { useLocation, useNavigate } from 'react-router';
import { ReactComponent as LogoIcon } from '../../assets/svg/logo.svg';
import { useState } from 'react';
// import LogoutConfirm from '../modals/logout';
import { shouldForwardProp } from '../../shared/util';
import palette from '../../shared/theme/palette';
import { useAuth0 } from '@auth0/auth0-react';
import LogoutConfirm from './LogoutConfirm';

const IconContainer = styled(Box, {
  shouldForwardProp: shouldForwardProp(['active']),
})(({ active }) => ({
  borderRadius: '2px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '.25rem',
  width: '2rem',
  height: '2rem',
  cursor: 'pointer',
  backgroundColor: active ? palette.primary[20] : 'transparent',
  '& svg': {
    color: active ? palette.primary[50] : 'black',
    height: '1.5rem',
    width: '1.5rem',
  },
}));

export default function Sidebar() {
  const navigate = useNavigate();
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);

  return (
    <>
      <Drawer
        variant="permanent"
        sx={{
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            boxSizing: 'border-box',
            backgroundColor: palette.primary[10],
            height: '100%',
            width: '64px',
            top: 'unset',
            borderRight: `1px solid ${palette.primary[30]}`,
          },
        }}
      >
        <Stack sx={{ pt: 3, pb: 5, px: 2, height: '100%' }} justifyContent="space-between">
          <Stack alignItems="center">
            <LogoIcon style={{ height: '1.5rem', width: '1.5rem', marginBottom: '3rem' }} />
            <Stack spacing={3.5}>
              <Tooltip title="Leases">
                <IconContainer active={true} onClick={() => navigate('/dashboard')}>
                  <PersonOutlineOutlinedIcon sx={{ color: palette.primary[50] }} />
                </IconContainer>
              </Tooltip>
            </Stack>
          </Stack>

          <Stack spacing={4}>
            <Tooltip title="Log Out">
              <IconContainer onClick={() => setShowLogoutConfirm(true)}>
                <ExitToAppRoundedIcon sx={{ height: '2rem', width: '2rem' }} />
              </IconContainer>
            </Tooltip>
          </Stack>
        </Stack>
      </Drawer>
      {showLogoutConfirm && <LogoutConfirm handleClose={() => setShowLogoutConfirm(false)} />}
    </>
  );
}
