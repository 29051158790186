import { Box, Chip, Divider, Stack, TextField, Typography } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { api } from '../../shared/service/dataprovider';
import palette from '../../shared/theme/palette';
import { formatPhoneNumber, getPersonFullName } from '../../shared/util';
import moment from 'moment';
import PaymentInfo from './PaymentInfo';
import ResmanLedger from './ResmanLedger';
import LeaseRequests from './LeaseRequests';
import { useEffect, useMemo, useState } from 'react';
import { IOSSwitch } from '../../components/switch';
import toast from 'react-hot-toast';
import ResetPasswordConfirmModal from './ResetPasswordConfirmModal';
import ResetPasswordModal from './ResetPasswordModal';
import CredBuildConfirmModal from './CredBuildConfirmModal';
import { useTranslation } from 'react-i18next';
import { CustomTooltip } from '../../components/tooltip';

export default function LeaseResident({ lease, leaseLink }) {
  const { data, error } = useQuery({
    queryKey: [`person/${leaseLink.id}`],
    queryFn: () => api.get(`/v1/admin/person/${leaseLink.people?.id}`, { params: { leaseId: lease.id } }),
  });
  const person = data?.data;

  if (error) {
    return (
      <Typography variant="h6" sx={{ color: palette.error[50] }}>
        {error?.response?.data?.message ?? `There is an error for loading person ${leaseLink.id}. Try again later`}
      </Typography>
    );
  }
  if (!person) return null;

  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', alignItems: 'start', gap: 3 }}>
      <ResidentDetail person={person} lease={lease} />
      <PaymentInfo person={person} lease={lease} />
      <Stack gap={3}>
        <ResmanLedger lease={lease} person={person} />
        <LeaseRequests person={person} />
      </Stack>
    </Box>
  );
}

function ResidentDetail({ person, lease }) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { data: userProfile } = queryClient.getQueryData(['user']);

  const [isEditingEmail, setEditingEmail] = useState(false);
  const [isEditingPhone, setEditingPhone] = useState(false);
  const [email, setEmail] = useState(person.relatedUser?.email ?? person.Email);
  const [phoneNumber, setPhoneNumber] = useState(person.HomePhone || person.WorkPhone || person.MobilePhone);
  const [showResetPasswordConfirmModal, setShowResetPasswordConfirmModal] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [showCredBuildConfirmModal, setShowCredBuildConfirmModal] = useState(false);

  useEffect(() => {
    setEmail(person.relatedUser?.email ?? person.Email);
    setPhoneNumber(person.HomePhone || person.WorkPhone || person.MobilePhone);
  }, [person]);

  const changeContactMutation = useMutation({
    mutationFn: contact => api.post('/v1/admin/change-contact', contact),
  });
  const toggleCredBuildMutation = useMutation({
    mutationFn: payload => api.post(`/v1/admin/user/${person.relatedUser.id}/toggle-credbuild-requirement`, payload),
  });
  const resetPasswordEmailMutation = useMutation({
    mutationFn: payload => api.post(`/v1/admin/reset-password-email`, payload),
  });
  const toggleSSNRequirementMutation = useMutation({
    mutationFn: payload => api.post(`/v1/admin/person/${person.id}/toggle-ssn-requirement`, payload),
  });
  const resendInvitationMutation = useMutation({
    mutationFn: contact => api.post('/v1/admin/invite-user', contact),
    onSuccess: () => {
      toast.success(t('lease.success.invite', { property: lease.property.name }));
      queryClient.invalidateQueries(`person/${person.id}`);
    },
    onError: data => {
      toast.error(data?.response?.data?.err ?? data?.response?.data?.message ?? data?.response?.message ?? 'Failed to send invitation');
    },
  });
  const refreshLeaseResmanMutation = useMutation({
    mutationFn: payload => api.post(`/v1/admin/refresh-lease-resman`, payload),
  });

  const lastInvitation = useMemo(() => {
    const invitations = person.invitations ?? [];
    invitations.sort((i1, i2) => new Date(i2.updatedDate).getTime() - new Date(i1.updatedDate).getTime());

    return invitations[0];
  }, [person]);

  const handleSaveEmail = () => {
    changeContactMutation.mutate(
      {
        personId: person.id,
        email,
      },
      {
        onSuccess: () => {
          toast.success(`Email address for ${lease.property.name} has been changed.`);
          setEditingEmail(false);
          queryClient.invalidateQueries(`person/${person.id}`);
        },
      }
    );
  };

  const handleSavePhone = () => {
    changeContactMutation.mutate(
      {
        personId: person.id,
        phone: phoneNumber,
      },
      {
        onSuccess: () => {
          toast.success(`Phone number for ${lease.property.name} has been changed.`);
          setEditingPhone(false);
          queryClient.invalidateQueries(`person/${person.id}`);
        },
      }
    );
  };

  const handleResendInvitation = () => {
    resendInvitationMutation.mutate({
      propertyId: lease.property.id,
      personId: person.id,
    });
  };

  const handleCredBuildChange = () => {
    if (person.relatedUser) {
      toggleCredBuildMutation.mutate(
        { value: person.relatedUser.isCreditReportingEnabled !== null && person.relatedUser.isCreditReportingEnabled !== true ? true : false },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(`person/${person.id}`);
          },
        }
      );
    }
  };

  const handleToggleSSNRequirement = e => {
    toggleSSNRequirementMutation.mutate(
      { value: e.target.checked },
      {
        onSuccess: () => {
          toast.success(`SSN requirement was removed for ${lease.property.name}.`);
          queryClient.invalidateQueries(`person/${person.id}`);
        },
      }
    );
  };

  const handleResetPasswordEmail = () => {
    if (person.relatedUser) {
      resetPasswordEmailMutation.mutate(
        {
          userId: person.relatedUser.id,
        },
        {
          onSuccess: () => {
            toast.success(`Send reset password email for ${person.relatedUser.email}.`);
            queryClient.invalidateQueries(`person/${person.id}`);
          },
        }
      );
    }
  };

  const handelSyncWithResman = () => {
    refreshLeaseResmanMutation.mutate(
      {
        propertyId: lease.property.id,
        personId: person.id,
        unitNumber: lease.Unit,
        leaseId: lease.id,
      },
      {
        onSuccess: () => {
          toast.success(`Sync with resman for ${lease.property.name} successfully.`);
          queryClient.invalidateQueries(`person/${person.id}`);
        },
      }
    );
  };

  return (
    <Box
      sx={{
        p: 3,
        borderRadius: 2,
        boxShadow: '0px 0px 3px 0px rgba(0, 0, 0, 0.06), 0px 4px 12px 0px rgba(0, 0, 0, 0.10)',
      }}
    >
      <Stack direction="row" gap={1} justifyContent="space-between" sx={{ mb: 3 }}>
        <Stack direction="row" gap={1} alignItems="center">
          <Typography sx={{ fontWeight: 'bold', textTransform: 'uppercase', color: palette.grey[50] }}>{t('lease.resident.title')}</Typography>
          {!person.prospectId && (
            <CustomTooltip title={t('lease.resident.residentInfo')}>
              <InfoOutlinedIcon color="warning" sx={{ ...editIconSx, color: '#E49900' }} />
            </CustomTooltip>
          )}
          {person.prospectId && person.relatedUser && person.Email !== person.relatedUser?.email && (
            <CustomTooltip title={t('lease.resident.outsync')}>
              <InfoOutlinedIcon color="warning" sx={{ ...editIconSx, color: '#E49900' }} />
            </CustomTooltip>
          )}
        </Stack>
        {!person.prospectId && (
          <Typography onClick={handelSyncWithResman} color="primary" sx={{ fontWeight: 500, borderBottom: `1px solid #E4E8FC`, cursor: 'pointer' }}>
            {t('lease.resident.sync')}
          </Typography>
        )}
      </Stack>
      <Stack gap={2}>
        <Typography>
          <Box component="span" sx={{ color: palette.grey[50] }}>
            {t('lease.resident.name')}{' '}
          </Box>
          <Box component="span" sx={{ color: palette.grey[80] }}>
            {getPersonFullName(person)}
          </Box>
        </Typography>
        {isEditingEmail ? (
          <Stack gap={2} sx={{ my: 1 }}>
            <Box>
              <TextField fullWidth label="Email Address" type="email" value={email} onChange={e => setEmail(e.target.value)} />
              <Typography variant="overline" color="grey.400">
                {t('lease.resident.editingEmail')}
              </Typography>
            </Box>
            <Stack gap={1.5} flexDirection="row" justifyContent="flex-end">
              <Typography
                onClick={() => {
                  setEmail(person.relatedUser?.email ?? person.Email);
                  setEditingEmail(false);
                }}
                color="grey.500"
                sx={{ fontWeight: 500, borderBottom: `1px solid #E4E8FC`, cursor: 'pointer' }}
              >
                {t('common.cancel')}
              </Typography>
              <Typography onClick={handleSaveEmail} color="primary" sx={{ fontWeight: 500, borderBottom: `1px solid #E4E8FC`, cursor: 'pointer' }}>
                {t('common.save')}
              </Typography>
            </Stack>
          </Stack>
        ) : (
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography>
              <Box component="span" sx={{ color: palette.grey[50] }}>
                {t('lease.resident.email')}{' '}
              </Box>
              <Box component="span" sx={{ color: palette.grey[80] }}>
                {person.relatedUser?.email ?? person.Email}
              </Box>
            </Typography>
            {person.prospectId && <EditOutlinedIcon onClick={() => setEditingEmail(true)} color="primary" sx={editIconSx} />}
          </Stack>
        )}
        {isEditingPhone ? (
          <Stack gap={2} sx={{ my: 1 }}>
            <TextField fullWidth label="Phone Number" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
            <Stack gap={1.5} flexDirection="row" justifyContent="flex-end">
              <Typography
                onClick={() => {
                  setPhoneNumber(person.HomePhone || person.WorkPhone || person.MobilePhone);
                  setEditingPhone(false);
                }}
                color="grey.500"
                sx={{ fontWeight: 500, borderBottom: `1px solid #E4E8FC`, cursor: 'pointer' }}
              >
                {t('common.cancel')}
              </Typography>
              <Typography onClick={handleSavePhone} color="primary" sx={{ fontWeight: 500, borderBottom: `1px solid #E4E8FC`, cursor: 'pointer' }}>
                {t('common.save')}
              </Typography>
            </Stack>
          </Stack>
        ) : (
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography>
              <Box component="span" sx={{ color: palette.grey[50] }}>
                {t('lease.resident.phone')}{' '}
              </Box>
              <Box component="span" sx={{ color: palette.grey[80] }}>
                {formatPhoneNumber(person.HomePhone || person.WorkPhone || person.MobilePhone)}
              </Box>
            </Typography>
            {person.prospectId && <EditOutlinedIcon onClick={() => setEditingPhone(true)} color="primary" sx={editIconSx} />}
          </Stack>
        )}
        <Typography>
          <Box component="span" sx={{ color: palette.grey[50] }}>
            {t('lease.resident.leasingFrom')}{' '}
          </Box>
          <Box component="span" sx={{ color: palette.grey[80] }}>
            {`${moment(lease.LeaseStartDate).utc().format('MM/DD/YYYY')} - ${moment(lease.LeaseEndDate).utc().format('MM/DD/YYYY')}`}
          </Box>
        </Typography>
        {/* <Typography>
          <Box component="span" sx={{ color: palette.grey[50] }}>
            Property:{' '}
          </Box>
          <Box component="span" sx={{ color: palette.grey[80] }}>
            {lease.property.name}
          </Box>
        </Typography>
        <Typography>
          <Box component="span" sx={{ color: palette.grey[50] }}>
            Unit Number:{' '}
          </Box>
          <Box component="span" sx={{ color: palette.grey[80] }}>
            {lease.Unit}
          </Box>
        </Typography> */}
      </Stack>
      <Divider sx={{ my: 2 }} />
      <Stack gap={2}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography>
            <Box component="span" sx={{ color: palette.grey[50] }}>
              {t('lease.resident.registered')}
            </Box>
          </Typography>
          <Chip color={person.relatedUser ? 'success' : 'info'} label={t(`common.${person.relatedUser ? 'success' : 'pending'}`)} />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography>
            <Box component="span" sx={{ color: palette.grey[50] }}>
              {t('lease.resident.lastLogin')}
            </Box>
          </Typography>
          {lease.lastLogin && (
            <Typography>
              <Box component="span" sx={{ color: palette.grey[80] }}>
                {moment(person.relatedUser?.lastLogin).format('MM/DD/YYYY')}
                {' at '}
                {moment(person.relatedUser?.lastLogin).format('h:mm A')}
              </Box>
            </Typography>
          )}
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography>
            <Box component="span" sx={{ color: palette.grey[50] }}>
              {t('lease.resident.ssnVerified')}
            </Box>
          </Typography>
          <Chip color={kycStatusColor[person.kycStatus]} label={t(`common.${kycStatusLabel[person.kycStatus]}`)} />
        </Stack>
        {userProfile.isSuperAdmin && person.relatedUser && (
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography>
              <Box component="span" sx={{ color: palette.grey[50] }}>
                {t('lease.resident.credbuild')}
              </Box>
            </Typography>
            <IOSSwitch
              checked={person.relatedUser.isCreditReportingEnabled !== null && person.relatedUser.isCreditReportingEnabled !== true}
              onChange={() => setShowCredBuildConfirmModal(true)}
            />
          </Stack>
        )}
      </Stack>
      <Divider sx={{ my: 2 }} />
      <Stack gap={2}>
        {person.kycStatus !== 'Success' && (
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography>
              <Box component="span" sx={{ color: palette.grey[50] }}>
                {t('lease.resident.removeSSN')}
              </Box>
            </Typography>
            <IOSSwitch checked={person.refusedKyc} onChange={handleToggleSSNRequirement} />
          </Stack>
        )}
        {person.relatedUser && (
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography>
              <Box component="span" sx={{ color: palette.grey[50] }}>
                {t('lease.resident.password')}{' '}
              </Box>
              <Box component="span" sx={{ color: palette.grey[80] }}>
                {'************'}
              </Box>
            </Typography>
            {userProfile.isSuperAdmin ? (
              <Stack direction="row" alignItems="center" gap={1}>
                <Typography onClick={setShowResetPasswordConfirmModal} color="primary" sx={{ cursor: 'pointer', borderBottom: '1px solid #E4E8FC' }}>
                  {t('common.reset')}
                </Typography>
                <Divider variant="horizontal" />
                <Typography onClick={() => setShowResetPasswordModal(true)} color="primary" sx={{ cursor: 'pointer', borderBottom: '1px solid #E4E8FC' }}>
                  {t('common.change')}
                </Typography>
              </Stack>
            ) : (
              <Typography onClick={() => setShowResetPasswordConfirmModal(true)} color="primary" sx={{ cursor: 'pointer', borderBottom: '1px solid #E4E8FC' }}>
                {t('lease.resident.resetPassword')}
              </Typography>
            )}
          </Stack>
        )}

        {!person.relatedUser && (
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography>
              <Box component="span" sx={{ color: palette.grey[50] }}>
                {t('lease.resident.inviteSent')}{' '}
              </Box>
              <Box component="span" sx={{ color: palette.grey[80] }}>
                {lastInvitation?.updatedDate ? moment(lastInvitation?.updatedDate).format('MM/DD/YY') : ''}
              </Box>
            </Typography>
            {lastInvitation?.updatedDate && moment().diff(moment(lastInvitation?.updatedDate), 'hour') < 24 ? (
              <Typography color="grey.800" sx={{ color: 'rgba(39, 41, 55, 0.35)', fontWeight: 500 }}>
                {person.invitations?.length > 1 ? t('lease.resident.inviteResent') : t('lease.resident.invitationSent')}
              </Typography>
            ) : (
              <Typography onClick={handleResendInvitation} color="primary" sx={{ cursor: 'pointer', borderBottom: '1px solid #E4E8FC' }}>
                {lastInvitation?.updatedDate ? t('lease.resident.resendInvitation') : t('lease.resident.sendInvitation')}
              </Typography>
            )}
          </Stack>
        )}
      </Stack>
      {showResetPasswordConfirmModal && (
        <ResetPasswordConfirmModal
          onClose={() => setShowResetPasswordConfirmModal(false)}
          onSubmit={() => {
            handleResetPasswordEmail();
            setShowResetPasswordConfirmModal(false);
          }}
        />
      )}
      {showCredBuildConfirmModal && (
        <CredBuildConfirmModal
          onClose={() => setShowCredBuildConfirmModal(false)}
          onSubmit={() => {
            handleCredBuildChange();
            setShowCredBuildConfirmModal(false);
          }}
        />
      )}
      {showResetPasswordModal && person.relatedUser && (
        <ResetPasswordModal userId={person.relatedUser.id} residentName={lease.property.name} onClose={() => setShowResetPasswordModal(false)} />
      )}
    </Box>
  );
}

const editIconSx = {
  width: '20px',
  height: '20px',
  cursor: 'pointer',
};

const kycStatusLabel = {
  Skipped: 'skipped',
  Failed: 'failed',
  Success: 'success',
  NotRequired: 'notRequired',
};

const kycStatusColor = {
  Skipped: 'warning',
  Failed: 'error',
  Success: 'success',
  NotRequired: 'info',
};
