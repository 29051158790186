export const es = {
  landing: {
    title: 'Inicia sesión en tu cuenta',
    signin: 'Inicia sesión con Google',
  },
  lease: {
    back: 'Volver a la búsqueda',
    resident: {
      title: 'Residente',
      name: 'Nombre:',
      email: 'Correo electrónico:',
      phone: 'Número de teléfono:',
      leasingFrom: 'Arrendando desde:',
      password: 'Contraseña:',
      lastLogin: 'Último inicio de sesión',
      registered: 'Registrado en TenantPort',
      editingEmail: 'Este nuevo correo electrónico se actualizará automáticamente en Resman.',
      outsync: 'Los datos están actualmente desincronizados con Resman',
      ssnVerified: 'SSN verificado en TenantPort',
      credbuild: 'optar por no CredBuild',
      removeSSN: 'Eliminar requisito de SSN',
      resetPassword: 'Restablecer contraseña',
      inviteSent: 'Invitación enviada:',
      invitationSent: 'Invitación enviada',
      inviteResent: '¡Invitación reenviada!',
      resendInvitation: 'Reenviar invitación',
      sendInvitation: 'Enviar invitacion',
      residentInfo: 'Para editar los detalles del residente, actualice en Resman, luego presione sincronizar para mostrar los cambios',
    },
    payment: {
      title: 'Información de pago',
      empty: 'No hay métodos de pago',
      autoPay: 'Pago automático',
      autoPayDate: 'Fecha de pago automático',
      on: 'Activado',
      off: 'Desactivado',
      status: 'Estado del pago en línea',
      enabled: 'Habilitado',
      disabled: 'Deshabilitado',
      certifiedFunds: 'Solo fondos certificados',
      description: 'Los pagos en línea han sido deshabilitados debido a intentos fallidos. Por favor, contacta a tu RM para cambiar este estado.',
    },
    transaction: {
      title1: 'Libro mayor de Resman',
      title2: 'Transacciones',
      empty: 'No hay transacciones',
      viewAll: 'Ver todo',
    },
    request: {
      title: 'Solicitudes',
      empty: 'No hay órdenes de trabajo',
    },
    password: {
      resetTitle: '¿Está seguro de que desea restablecer su contraseña?',
      resetDescription: 'Recibirán un correo electrónico pidiéndoles que creen una nueva contraseña.',
      resetPassword: 'Restablecer Contraseña',
      changeTitle: 'Cambiar la Contraseña del Inquilino',
      changeDescription:
        'Esto le permite cambiar la contraseña en nombre del inquilino. Por favor, asegúrese de notificar al inquilino sobre la nueva contraseña.',
      changePassword: 'Cambiar Contraseña',
      credbuildTitle: '¿Está seguro de que desea deshabilitar y finalizar la suscripción a CredBuild del inquilino?',
      credbuildDescription: 'Esto tendrá efecto en el ciclo de facturación del próximo mes.',
    },
    success: {
      invite: 'Se ha reenviado la invitación a {{ property }}',
    },
  },
  logout: {
    title: 'Cerrar Sesión',
    description: '¿Está seguro de que desea cerrar sesión?',
    return: 'Volver',
  },
  pagination: {
    rowsPerPage: 'Filas por página',
  },
  properties: {
    title: 'Buscar Residentes',
    unitNumber: 'Número de Unidad',
    current: 'Actual',
    renewed: 'Renovado',
    pending: 'Pendiente',
    pendingTransfer: 'Transferencia Pendiente',
    former: 'Antiguo',
    evicted: 'Desalojado',
    underEviction: 'Bajo Desalojo',
    search: 'Seleccione un número de unidad para comenzar su búsqueda',
    residentName: 'Nombre(s) del Residente(s)',
    status: 'Estado del Arrendamiento',
    ssnVerified: 'SSN Verificado en TP',
    registered: 'Registrado en TP',
    startDate: 'Fecha de Inicio del Arrendamiento',
    endDate: 'Fecha de Fin del Arrendamiento',
    empty: 'No hay residentes según ese criterio de búsqueda.',
    emptySearchWithUnit: 'No hay residentes para la Unidad {{ unit }} según ese criterio de búsqueda.',
  },
  common: {
    cancel: 'Cancelar',
    save: 'Guardar',
    reset: 'Restablecer',
    change: 'Cambiar',
    close: 'Cerrar',
    success: 'Éxito',
    pending: 'Pendiente',
    skipped: 'Omitido',
    failed: 'Fallido',
    notRequired: 'No requerido',
    default: 'Predeterminado',
    disable: 'Desactivar',
  },
};
